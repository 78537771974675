import { SocioLabLink } from './SocioLabLink';

export const Footer = () => {
  return (
    <div id="footer">
      Questions? Email cloxhelp at uw.edu
      <br />
      <br />Developed at the <SocioLabLink>University of Washington Sociolinguistics Laboratory</SocioLabLink>
      <br />by Alicia Wassink, Rob Squizzero, Campion Fellin, and David Nichols
      <br />Webpage designed by R.L. Hugo
      <br />Powered by Microsoft Azure Cognitive Services.
      &copy;2020
    </div>
  )
}
