import clox_logo from '../images/clox_logo.jpg';
import clox_logo_mobile from '../images/clox_logo_mobile.jpg';

export const CloxLogo = () => {
  return (
    <>
      <img id="logo" src={clox_logo} alt="logo"/>
      <img id="logo_mobile" src={clox_logo_mobile} alt="logo_mobile" />   
    </>
  )
}
