export const ServiceRegions = (props: any) => {
  const { setServiceRegion } = props
  return (
    <select id="serviceRegion" onChange={(event) => setServiceRegion(event.target.value)}>
      <option value="australiaeast">australiaEast</option>
      <option value="canadacentral">canadaCentral</option>
      <option value="centralus">centralUS</option>
      <option value="eastus">eastUS</option>
      <option value="eastus2">eastUS2</option>
      <option value="francecentral">franceCentral</option>
      <option value="indiacentral">indiaCentral</option>
      <option value="japaneast">japanEast</option>
      <option value="koreacentral">koreaCentral</option>
      <option value="northcentralus">northCentralUS</option>
      <option value="northeurope">northEurope</option>
      <option value="southcentralus">southCentralUS</option>
      <option value="southeastasia">southeastAsia</option>
      <option value="uksouth">ukSouth</option>
      <option value="westeurope">westEurope</option>
      <option value="westus" selected>westUS</option>
      <option value="westus2">westUS2</option>
    </select>
  )
}
