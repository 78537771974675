import logo_white from '../images/W-Logo_White.png';
import logo_gold from '../images/W-Logo_Gold.png';
import { useState } from 'react'
import { SocioLabLink } from './SocioLabLink';

export const UWLogo = () => {

  const [logo, setLogo] = useState(logo_white)

  const logoHover = () => {
    setLogo(logo_gold)
  }

  const logoUnhover = () => {
    setLogo(logo_white)
  }

  return (
    <SocioLabLink>
      <img alt="uw_logo" id="uw_logo" src={logo} onMouseOver={logoHover} onMouseOut={logoUnhover}/>
    </SocioLabLink>
  )
}
