import React from 'react';
import VersionHistory from './pages/VersionHistory'
import CLOxTranscription from './pages/CLOxTranscription'
import Scripts from './pages/Scripts'
import Home from './pages/Home'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

function App() {

  return (
    <>
    <Router basename={process.env.PUBLIC_URL}>
      <Header />
      <Switch>
        <Route path={`/`} exact component={() => <Home />} />
        <Route path={`/scripts`} exact component={() => <Scripts />} />
        <Route path={`/versionhistory`} exact component={() => <VersionHistory />} />
        <Route path={`/cloxmacos`} exact component={() => <CLOxTranscription />} />
      </Switch>
      <Footer />
    </Router>
    </>
  );
}

export default App;
