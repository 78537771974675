import React, { useEffect, useRef, useState } from 'react';
import { contentStyle, majorRevisionsStyle } from '../components/Styles'
import apple from '../images/apple.png'
import microsoft from '../images/microsoft.png'
import linux from '../images/linux.png'
import { PythonInformationModal } from '../components/PythonInformationModal';
import { Subheader } from '../components/Subheader';
import ReactGA from 'react-ga'

function Scripts() {

  const pythonModalRef = useRef<HTMLDivElement>(null)

  const [showPythonModal, setShowPythonModal] = useState(false)

  const handleClickOutside = (event: any) => {
    if (pythonModalRef.current && pythonModalRef?.current !== event.target && !pythonModalRef?.current.contains(event.target)) {
        setShowPythonModal(false);
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [pythonModalRef]);

  return (
    <>
    <Subheader />
    <div id="content" style={contentStyle}>
        <div id="center_wrap" className="flex">
            <div style={majorRevisionsStyle}>
                <h2 style={{textAlign: 'center'}}>Helper scripts for audio preprocessing</h2>
            </div>
        <div style={majorRevisionsStyle}><p>
            <br />
        Two scripts are available for audio preprocessing; one in Praat, and one in Python.
        The advantages of the Praat script are that Python does not need to be installed and you do not need to know how to use a command-line interface.
        The advantage of the Python script is that it uses silence detection to break up files, meaning that the extracted files do not end during the middle of a speech event - 
        this may result in a higher quality transcription, as Microsoft Speech will not be analyzing truncated utterances.
        The Python script also has a faster runtime for larger audio files.
        </p></div>
        <div className="flex option_row" style={{height: '1px'}}></div>
        <div style={{textAlign: 'center', width: '90%', color: 'gray', marginLeft: '5%'}}>
            Note: clicking a link should prompt you to download the file. On some browsers, scripts may open as text files. If so, right-click or command-click the link 
            and click "Save Link As...” to the file indicated in the manual, where applicable.
        </div>
        <div id="software_links" style={{minHeight: '160px', textAlign: 'center', width: '60%', margin: '0 auto'}}>
            <div className="software" id="apple">
                <div><img style={{height: '131px'}} src={apple} alt="apple"/></div>
                <div>
                    <a href={`${process.env.PUBLIC_URL}/clox_preprocessing.praat`} download={`${process.env.PUBLIC_URL}/clox_preprocessing.praat`}>Praat script </a>
                    <br /><br />
                    Python Script<br />
                    <div>(Currently unavailable)</div><br />
                    <br />
            <br /></div>
            </div>
            <div className="software" id="microsoft">
                    <div><img src={microsoft} alt="microsoft" /></div>
                    <div><a href={`${process.env.PUBLIC_URL}/clox_preprocessing.praat`} download={`${process.env.PUBLIC_URL}/clox_preprocessing.praat`}>Praat script </a>
                        <br /><br />
                        <a href={`${process.env.PUBLIC_URL}/CLOx-preprocessing.zip`}>Python Script (.zip)</a><br /><br />
                <a href={`${process.env.PUBLIC_URL}/win_manual.pdf`}>Python Script Manual</a><br /></div>
                <div className="python_explanation" onClick={() => setShowPythonModal(true)}>About the Python script</div><br />
            </div>
            <div className="software" id="linux">
                    <div><img src={linux} alt="linux"/></div>
                    <div><a href={`${process.env.PUBLIC_URL}/clox_preprocessing.praat`} download={`${process.env.PUBLIC_URL}/clox_preprocessing.praat`}>Praat script </a>
                        <br /><br />
                        Python Script<br />
                        <div>(Currently unavailable)</div><br />
                    <br />
                <br /></div>
            </div>
            <div className="flex option_row" style={{height: '1px'}}></div>
        </div>
        { showPythonModal && <PythonInformationModal setShowPythonModal={setShowPythonModal} pythonModalRef={pythonModalRef}/> }
        </div>    
    </div>
    </>
  );
}

export default Scripts;
