import { Link } from 'react-router-dom'

export const Subheader = () => {
  return (
    <div id="subheader" className="flex">
      <p>
        <Link to={`/`}>Home </Link>|
        <a href={`${process.env.PUBLIC_URL}/CLOx_Guide.pdf`} target="_blank" rel='noopener noreferrer'> Guide (pdf) </a>|
        <Link to={`/scripts`}> Scripts </Link>|
        <Link to={`/versionhistory`}> Version History </Link>|
        <Link to={`/cloxmacos`}> CLOx for macOS</Link>
      </p>
    </div>  
  )
}
