import { Link } from 'react-router-dom'

export const SubheaderNewTab = () => {
  return (
    <div id="subheader" className="flex">
      <p>
        <Link to={`/`} target="_blank" rel='noopener noreferrer'>Home </Link>|
        <a href={`${process.env.PUBLIC_URL}/CLOx_Guide.pdf`} target="_blank" rel='noopener noreferrer'> Guide (pdf) </a>|
        <Link to={`/scripts`} target="_blank" rel='noopener noreferrer'> Scripts </Link>|
        <Link to={`/versionhistory`} target="_blank" rel='noopener noreferrer'> Version History </Link>|
        <Link to={`/cloxmacos`} target="_blank" rel='noopener noreferrer'> CLOx for macOS</Link>
      </p>
    </div>  
  )
}
