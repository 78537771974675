export const preRecognitionValidation = (subscriptionKey: string ,preProcessCheckboxChecked: boolean) => {
  if (subscriptionKey === '' || subscriptionKey === 'Your Microsoft Speech API Key') {
    alert('Please enter your Microsoft Speech subscription key!');
    return
  }

  if (/^[a-zA-Z0-9]+$/i.test(subscriptionKey) === false) {
    alert('API keys only contain alphanumeric characters. Please check your key and try again.')
    return;
  } 

  if (preProcessCheckboxChecked === false) {
    alert("Please confirm that your files have been preprocessed before proceeding.")
    return;
  }
}
