import *  as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk'

export interface FileWithReco {
  fileName: string
  reco: SpeechSDK.SpeechRecognizer
}

export const filePickerChange = (
  files: FileList | null,
  setContinousRecognitionDisabled: any,
  subscriptionKeyValue: string,
  serviceRegion: string,
  languageOptions: string,
) => {

  console.log(files);

  let fileRecos: FileWithReco[] = []

  setContinousRecognitionDisabled(true)
  let audioConfig;

  const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKeyValue, serviceRegion);
  
  speechConfig.speechRecognitionLanguage = languageOptions;

  
  Array.from(files ? files : []).forEach((file: File) => {
    audioConfig = SpeechSDK.AudioConfig.fromWavFileInput(file);
    const file_reco = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    if (file.size > 19200044) {
      alert('Your file exceeds the maximum allowed file size. It must be 19.2MB or less');
      return;
    } else {
      fileRecos.push({ fileName: file.name, reco: file_reco})
    }
  })

  console.log(fileRecos)
  return fileRecos;
}

export const recognize = (namedRecos: FileWithReco[], setPhraseDivContent: any, outFile: string) => {
  let allRows: string[] = ['Text,', 'Onset,', 'Offset']

  let lastRecognized = ''

  namedRecos.forEach(async ({fileName, reco }) => {
    let curFileRows: string = ''

    reco.recognizing = function (s, e) {
      setPhraseDivContent(lastRecognized + e.result.text);
    };

    // The event recognized signals that a final recognition result is received.
    // This is the final event that a phrase has been recognized.
    // For continuous recognition, you will get one recognized event for each phrase recognized.
    reco.recognized = (s: SpeechSDK.Recognizer, e: SpeechSDK.SpeechRecognitionEventArgs) => {
      // Indicates that recognizable speech was not detected, and that recognition is done.
      if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
          const noMatchDetail = SpeechSDK.NoMatchDetails.fromResult(e.result);
          console.log(`noMatchDetail is ${noMatchDetail}`)
      }
      //Manipulation of output
      lastRecognized += e.result.text + "\r\n";
      const curFile = fileName
      const indexStart = curFile.lastIndexOf("_") + 1;
      const indexEnd = curFile.lastIndexOf(".");
      let timeStart = +curFile.substring(indexStart, indexEnd);
      if (isNaN(timeStart)) {
        timeStart = 0;
      }
      const text = e.result.text;
      const noPunctuation = text.replace(/[.,/#!$?%^&*;:{}=\-_`~()]/g,"");
      const finalText = noPunctuation.replace(/\s{2,}/g," ");
      const onset = (e.result.offset / 10000000) + (timeStart / 1000);
      const offset = (e.result.duration / 10000000) + onset;
      let rowArray = [finalText, onset, offset]
      let row = rowArray.join();
      curFileRows += "\r\n" + row;
    }

    // The event signals that the service has stopped processing speech.
    // https://docs.microsoft.com/javascript/api/microsoft-cognitiveservices-speech-sdk/speechrecognitioncanceledeventargs?view=azure-node-latest
    // This can happen for two broad classes of reasons.
    // 1. An error is encountered.
    //    In this case the .errorDetails property will contain a textual representation of the error.
    // 2. Speech was detected to have ended.
    //    This can be caused by the end of the specified file being reached, or ~20 seconds of silence from a microphone input.
    reco.canceled = function (s, e) {
      console.log(e);
      if (e.reason === SpeechSDK.CancellationReason.Error) {
        if (e.errorDetails.includes('StatusCode: 1006')) {
          alert('HTTP Authentication failed; no valid credentials available.\n\nThis usually indicates that either your API key is invalid or it is for a different region than you have selected.');
        }
      }
    };

    // Signals that a new session has started with the speech service
    reco.sessionStarted = function (s, e) {
      console.log(e);
    };

    // Signals the end of a session with the speech service.
    reco.sessionStopped = function (s, e) {
      allRows.push(curFileRows);

      setPhraseDivContent('\r\n Transcription finished and downloaded to default downloads folder.')

      if (allRows.length - 3 === namedRecos.length) {
        const csv = allRows;
        // make a new file
        const blob = new Blob(csv);
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        let outputDir = outFile;
        if (!outputDir.endsWith(".csv")) {
          outputDir += ".csv";
        }
        a.download = outputDir;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };

    // Signals that the speech service has started to detect speech.
    reco.speechStartDetected = function (s, e) {
      console.log(e);
    };

    // Signals that the speech service has detected that speech has stopped.
    reco.speechEndDetected = function (s, e) {
      console.log(e);
    };

    await reco.startContinuousRecognitionAsync();
  })
}