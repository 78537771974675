import { useEffect, useRef, useState } from 'react';
import { contentStyle } from '../components/Styles'
import { ServiceRegions } from '../components/ServiceRegions';
import { LanguageOptions } from '../components/LanguageOptions';
import { APIInformationModal } from '../components/APIInformationModal';
import { PreprocessModal } from '../components/PreprocessModal';
import { safeGetSubscriptionKey } from '../js/SafeGetSubscriptionKey'; 
import { preRecognitionValidation } from '../js/PreRecognitionValidation';
import { filePickerChange, FileWithReco, recognize } from '../js/FilePicker';
import { SubheaderNewTab } from '../components/SubHeaderNewTab';
import ReactGA from 'react-ga'

function Home() {
  const phraseDivRef = useRef(null)

  const [subscriptionKeyValue, setSubscriptionKeyValue] = useState(safeGetSubscriptionKey())
  const [outputFileName, setOutputFileName] = useState('example')
  const [showPreprocessModal, setShowPreprocessModal] = useState(false)
  const [showApiModal, setShowApiModal] = useState(false)
  const [continousRecognitionDisabled, setContinousRecognitionDisabled] = useState(false)
  const [serviceRegion, setServiceRegion] = useState('westus')
  const [languageOptions, setLanguageOptions] = useState('en-US')
  const [phraseDivConent, setPhraseDivContent] = useState('')
  const [filePickerValue, setFilePickerValue] = useState('')

  const preProcessModalRef = useRef<HTMLDivElement>(null)
  const apiModalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    //@ts-ignore
    phraseDivRef.current.scrollTop = phraseDivRef?.current?.scrollHeight
  }, [phraseDivConent]);

  const [preProcessCheckboxChecked, setPreProcessCheckboxChecked] = useState(false)

  // let recos: FileWithReco[] = []
  const [recos, setRecos] = useState<FileWithReco[]>()

  useEffect(() => {
    localStorage.setItem('subscriptionKey', subscriptionKeyValue ? subscriptionKeyValue : '');
    console.log(outputFileName)
  }, [subscriptionKeyValue, outputFileName])

  const fileButtonClicked = (event: any) => {
    console.log("fileButtonClicked")
    const recosLocal = filePickerChange(event.target.files, setContinousRecognitionDisabled, subscriptionKeyValue, serviceRegion, languageOptions)
  
    recognize(recosLocal, setPhraseDivContent, outputFileName);

    setRecos(recosLocal)
  }

  const stopButtonClicked = () => {
    recos && recos.forEach(async ({fileName, reco}: FileWithReco) => {
      await reco.stopContinuousRecognitionAsync(
        function () {
          reco.close();
          setPhraseDivContent('\r\n Transcription stopped and downloaded to default downloads folder.');
        },
        function (err) {
            reco.close();
        });
    })
  }

  const subscriptionKeyFocus = () => {
    if (subscriptionKeyValue === 'Your Microsoft Speech API Key') {
      setSubscriptionKeyValue('')
    }
  }

  const handleClickOutside = (event: any) => {
    if (preProcessModalRef.current && preProcessModalRef?.current !== event.target && !preProcessModalRef?.current.contains(event.target)) {
      setShowPreprocessModal(false);
    }

    if (apiModalRef.current && apiModalRef?.current !== event.target && !apiModalRef?.current.contains(event.target)) {
      setShowApiModal(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [apiModalRef, preProcessModalRef]);

  return (
    <>
      <SubheaderNewTab />
      <div id="content" style={contentStyle}>
        <div id="center_wrap" className="flex">                
          <div className="flex option_row">
            <div className="option_number">1
            </div>
            <div className="option_label">
              <p>API KEY</p>
            </div>
            <input id="subscriptionKey" type="text" size={40} value={subscriptionKeyValue} onChange={(event) => setSubscriptionKeyValue(event.target.value)} onFocus={subscriptionKeyFocus} onBlur={() => setSubscriptionKeyValue(safeGetSubscriptionKey())}/>
            <div id="api_explanation" onClick={() => setShowApiModal(true)}>?</div>
          </div>
          <div className="flex option_row">
            <div className="option_number">2
            </div>
            <div className="option_label">Region
            </div>
            <ServiceRegions setServiceRegion={setServiceRegion} />
            <div className="placeholder_explanation">?
            </div>
          </div>
          <div className="flex option_row">
              <div className="option_number">3</div>
              <div className="option_label">Language</div>
              <LanguageOptions setLanguageOptions={setLanguageOptions} />
            <div className="placeholder_explanation">?</div>
          </div>
          <div className="flex option_row">
            <div className="option_number">4</div>
            <div className="option_label">Output File Name</div>
            <input id="fileName" type="text" size={40} value={outputFileName} onFocus={() => setOutputFileName('')} onChange={(event) => setOutputFileName(event.target.value)}/>
            <div className="placeholder_explanation">?</div>
          </div>
          <div className="flex option_row">
            <div className="option_number">5
            </div>
            <div className="option_label">Preprocessing
            </div>
            <div id="preprocessing">
              <input id="preprocess_checkbox" type="checkbox" className="checkbox" checked={preProcessCheckboxChecked} onChange={(event) => setPreProcessCheckboxChecked(event.target.checked)} /><label>Audio is preprocessed</label>
            </div>
            <div id="preprocess_explanation" onClick={() => setShowPreprocessModal(true)}>?
            </div>
          </div>
          <div className="flex option_column">
            <div className="info">
              Click the "Select Files and Start" button below to select audio and begin transcription. 
              To select multiple files, use ctrl+click, cmd+click or shift+click in the file selection menu that appears after clicking.
            </div>
            <button id="startContinuousRecognition" onClick={() => preRecognitionValidation(subscriptionKeyValue, preProcessCheckboxChecked)} disabled={continousRecognitionDisabled}>
              <label>Select Files and Start
                <input type="file" id="filePicker" accept=".wav" multiple style={{display: 'none'}} value={filePickerValue} onClick={() => setFilePickerValue('')} onChange={fileButtonClicked} />
              </label>
            </button>  
            <button id="speechsdkStopContinuousRecognition" onClick={stopButtonClicked}>Stop</button>
            <button id="createCSVbtn" style={{display: 'none'}}>Create CSV</button>  
          </div>
          <div className="flex option_row margin_mobile">
            <div className="status">Results
            </div>
            <div id="statusDiv">
              <div id="phraseDiv" ref={phraseDivRef}>
                {phraseDivConent}
              </div>
            </div>
          </div>
          <table width="100%">
          </table>
          { showApiModal && <div> <APIInformationModal setShowApiModal={setShowApiModal} apiModalRef={apiModalRef} /></div>}
          { showPreprocessModal && <div> <PreprocessModal setShowPreprocessModal={setShowPreprocessModal} preProcessModalRef={preProcessModalRef} /></div>}
        </div>
      </div>
    </>
  );
}

export default Home;
