import { useEffect } from 'react';
import { contentStyle } from '../components/Styles'
import { Subheader } from '../components/Subheader';
import apple from '../images/apple.png'
import ReactGA from 'react-ga'

function CLOxTranscription() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])
  return (
    <>
      <Subheader />
      <div id="content" style={contentStyle}>
        <div id="center_wrap" className="flex">
            <div>
                <h2 style={{textAlign: 'center'}}>CLOx Transcription App for Mac</h2>
                <h3 style={{textAlign: 'center'}}>CLOx Transcription is the natural extension of CLOx</h3>
                <br />
                <br />
                <p style={{textAlign: 'center'}}>
                  If you have a computer with macOS, you can download CLOxTranscription as a desktop app. This
                  app uses Apple/Siri's Speech-to-text system rather than the Microsoft/Azure system.
                
                  <div><img style={{height: '131px'}} src={apple} alt="apple"/></div>
               
                  <div>
                    <a href={'https://apps.apple.com/us/app/clox-transcription/id1523222131'} target="_blank" rel='noopener noreferrer'>Open in App Store</a>
                  </div>
                  <br />
                  <br />
                </p>
            </div>
        </div>
    </div>
    </>
  );
}

export default CLOxTranscription;
