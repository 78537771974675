export const contentStyle = {
  display: "-webkit-box -moz-box -ms-flexbox flex",
  flexDirection: "column",
  justifyContent: "center",
} as React.CSSProperties

export const majorRevisionsStyle = {
  marginLeft: '5em',
  textAlign: 'left',
  marginRight: '5em'
} as React.CSSProperties
