export const PreprocessModal = (props: any) => {
  const { setShowPreprocessModal } = props

  return (
    <div id="preprocess_modal" className="modal">
      <div ref={props.preProcessModalRef} className="modal-content">
        <span id="close_modal" className="close" onClick={() => setShowPreprocessModal(false)}>&times;
        </span>
        <p>Audio files must be in .wav format, mono, and sampled at 16000 Hz, and under 19 MB in size, which is about 9.5 minutes of speech under those specifications. 
          Audio filenames must end in “_startTime.wav,” where “startTime” is the beginning of the file in milliseconds. Use "_0.wav" if you do not need to adjust your timestamp.
          If your file(s) need formatting, you may use our <a href={`${process.env.PUBLIC_URL}/clox_preprocessing.praat`}>Praat script</a>, or (2) pre-process it manually (see <a href="CLOx_Guide.pdf">Guide</a>).
          If audio is not preprocessed, CLOx may crash without notification or produce undesirable output.</p>
      </div>
    </div>
  )
}
