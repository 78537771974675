export const APIInformationModal = (props: any) => {
  const { setShowApiModal } = props

  return (
    <div id="api_modal" className="modal">
        <div ref={props.apiModalRef} className="modal-content">
          <span id="close_api_modal" className="close" onClick={() => setShowApiModal(false)}>&times;
          </span>
          <p>An API key from Microsoft Cognitive Services is needed to use this service. See <a href="CLOx_Guide.pdf">Guide</a> for details.
              Note: Bing Speech API keys no longer work for this service. Legacy CLOx has been discontinued.
          </p>
        </div>
    </div>
  )
}
