import { useEffect } from 'react';
import { contentStyle, majorRevisionsStyle } from '../components/Styles'
import { Subheader } from '../components/Subheader';
import ReactGA from 'react-ga'

function VersionHistory() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <>
      <Subheader />
      <div id="content" style={contentStyle}>
        <div id="center_wrap" className="flex">
            <div style={majorRevisionsStyle}>
                <h2 style={{textAlign: 'center'}}>Major revisions</h2>
            </div>
            <div style={majorRevisionsStyle}>
                <br />
                <dl>
                    <dt>7/15/2021</dt>
                    <li>New languages: Filipino, Greek, Hebrew, Indonesian, Malay, Vietnamese</li>
                    <li>Additional dialect support for: Arabic (Algeria, Libya, Morocco, Tunisia, Yemen), English (Ghana, Kenya, Tanzania), French (Switzerland), German (Austria) </li>
                    <br /><br />
                    <dt>3/29/2021</dt>
                    <li>CSV export no longer rounds timestamps to 2 decimal places.</li>
                    <br /><br />
                    <dt>1/20/2021</dt>
                    <li>New languages: Bulgarian, Czech, Estonian, Hungarian, Irish, Latvian, Lithuanian, Maltese, Romanian, Slovak, Slovenian</li>
                    <li>Additional dialect support for: Arabic, English, Spanish </li>
                    <br /><br />
                    <dt>12/15/2020</dt>
                    <li>Bug fixes and usability improvements</li>
                    <br /><br />
                    <dt>6/30/2020</dt>
                    <li>Praat preprocessing script now trims silences to 1 second to avoid timeout errors</li>
                    <li>Praat preprocessing script now prompts the user to select the input audio file</li>
                    <br /><br />
                    <dt>4/15/2020</dt>
                    <li>New languages: Gujarati, Marathi, Tamil, Telugu, Turkish</li>
                    <li>New dialect support: Arabic</li>
                    <li>Languages menu now specifies country and orthography, where applicable</li>
                    <li>Guide updated</li>
                    <li>Bing Speech and Legacy CLOx discontinued</li>
                    <br /><br />
                    <dt>9/10/2019</dt>
                    <li>CLOx 2.0 published, now uses Microsoft Speech service</li>
                    <li>Audio files up to 10 minutes, or 19.2 MB, now supported</li>
                    <li>New languages: Catalan, Danish, Finnish, Hindi, Korean, Norwegian, Dutch, Polish, Swedish, Thai</li>
                    <li>New dialect support: Chinese, English, French, Spanish, and Portuguese</li>
                    <li>Legacy CLOx available at clox.ling.washington.edu/legacy.</li>
                    <br /><br />
                    <dt>10/25/2018</dt>
                    <li>Azure stops issuing Bing Speech API Keys</li>
                    <br /><br />
                    <dt>10/18/2018</dt>
                    <li>CLOx demonstrated at NWAV 47 workshop</li>
                    <br /><br />
                    <dt>6/26/2018</dt>
                    <li>CLOx first published</li>
                </dl>
            </div>
        </div>
    </div>
    </>
  );
}

export default VersionHistory;
