export const LanguageOptions = (props: any) => {
  const { setLanguageOptions } = props

  return (
    <select id="languageOptions" onChange={(event) => setLanguageOptions(event.target.value)}>
      <option value="ar-DZ">Arabic - Algeria</option>
      <option value="ar-BH">Arabic - Bahrain, modern standard</option>
      <option value="ar-EG">Arabic - Egypt</option>
      <option value="ar-IQ">Arabic - Iraq</option>
      <option value="ar-IL">Arabic - Israel</option>
      <option value="ar-JO">Arabic - Jordan</option>
      <option value="ar-KW">Arabic - Kuwait</option>
      <option value="ar-LB">Arabic - Lebanon</option>
      <option value="ar-LY">Arabic - Libya</option>
      <option value="ar-MA">Arabic - Morocco</option>
      <option value="ar-OM">Arabic - Oman</option>
      <option value="ar-PS">Arabic - Palestinian Authority</option>
      <option value="ar-QA">Arabic - Qatar</option>
      <option value="ar-SA">Arabic - Saudi Arabia</option>
      <option value="ar-SY">Arabic - Syria</option>
      <option value="ar-TU">Arabic - Tunisia</option>
      <option value="ar-AE">Arabic - United Arab Emirates</option>
      <option value="ar-YE">Arabic - Yemen</option>
      <option value="bg-BG">Bulgarian - Bulgaria</option>
      <option value="ca-ES">Catalan - Spain</option>
      <option value="zh-HK">Chinese - Cantonese, traditional</option>
      <option value="zh-CN">Chinese - Mandarin, simplified</option>
      <option value="zh-TW">Chinese - Taiwanese Mandarin</option>
      <option value="hr-HR">Croatian - Croatia</option>
      <option value="cz-CZ">Czech - Czech Republic</option>
      <option value="da-DK">Danish - Denmark</option>
      <option value="nl-NL">Dutch - Netherlands</option>
      <option value="en-AU">English - Australia</option>
      <option value="en-CA">English - Canada</option>
      <option value="en-GH">English - Ghana</option>
      <option value="en-HK">English - Hong Kong</option>
      <option value="en-IN">English - India</option>
      <option value="en-IE">English - Ireland</option>
      <option value="en-KE">English - Kenya</option>
      <option value="en-NZ">English - New Zealand</option>
      <option value="en-NG">English - Nigeria</option>
      <option value="en-PH">English - Philippines</option>
      <option value="en-SG">English - Singapore</option>
      <option value="en-ZA">English - South Africa</option>
      <option value="en-TZ">English - Tanzania</option>
      <option value="en-GB">English - United Kingdom</option>
      <option value="en-US" selected>English - United States</option>
      <option value="et-EE">Estonian - Estonia</option>
      <option value="fil-PH">Filipino - Philippines</option>
      <option value="fi-FI">Finnish - Finland</option>
      <option value="fr-CA">French - Canada</option>
      <option value="fr-FR">French - France</option>
      <option value="fr-CH">French - Switzerland</option>
      <option value="de-AT">German - Austria</option>
      <option value="de-DE">German - Germany</option>
      <option value="el-GR">Greek - Greece</option>
      <option value="gu-IN">Gujarati - India</option>
      <option value="he-IL">Hebrew - Israel</option>
      <option value="hi-IN">Hindi - India</option>
      <option value="hu-HU">Hungarian - Hungary</option>
      <option value="id-ID">Indonesian - Indonesia</option>
      <option value="ga-IE">Irish - Ireland</option>
      <option value="it-IT">Italian - Italy</option>
      <option value="ja-JP">Japanese - Japan</option>
      <option value="ko-KR">Korean - Korea</option>
      <option value="lv-LV">Latvian - Latvia</option>
      <option value="lt-LT">Lithuanian - Lithuania</option>
      <option value="ms-MY">Malay - Malaysia</option>
      <option value="mt-MT">Maltese - Malta</option>
      <option value="mr-IN">Marathi - India</option>
      <option value="nb-NO">Norwegian - Norway (Bokmål)</option>
      <option value="pl-PL">Polish - Poland</option>
      <option value="pt-BR">Portuguese - Brazil</option>
      <option value="pt-PT">Portuguese - Portugal</option>
      <option value="ro-RO">Romanian - Romania</option>
      <option value="ru-RU">Russian - Russia</option>
      <option value="sk-SK">Slovak - Slovakia</option>
      <option value="sl-SL">Slovenian - Slovenia</option>
      <option value="es-AR">Spanish - Argentina</option>
      <option value="es-BO">Spanish - Bolivia</option>
      <option value="es-CL">Spanish - Chile</option>
      <option value="es-CO">Spanish - Colombia</option>
      <option value="es-CR">Spanish - Costa Rica</option>
      <option value="es-CU">Spanish - Cuba</option>
      <option value="es-DO">Spanish - Dominican Republic</option>
      <option value="es-EC">Spanish - Ecuador</option>
      <option value="es-SV">Spanish - El Salvador</option>
      <option value="es-GQ">Spanish - Equatorial Guinea</option>
      <option value="es-GT">Spanish - Guatemala</option>
      <option value="es-HN">Spanish - Honduras</option>
      <option value="es-MX">Spanish - Mexico</option>
      <option value="es-NI">Spanish - Nicaragua</option>
      <option value="es-PA">Spanish - Panama</option>
      <option value="es-PY">Spanish - Paraguay</option>
      <option value="es-PE">Spanish - Peru</option>
      <option value="es-PR">Spanish - Puerto Rico</option>
      <option value="es-ES">Spanish - Spain</option>
      <option value="es-UY">Spanish - Uruguay</option>
      <option value="es-US">Spanish - United States</option>
      <option value="es-VE">Spanish - Venezuela</option>
      <option value="sv-SE">Swedish - Sweden</option>
      <option value="ta-IN">Tamil - India</option>
      <option value="te-IN">Telugu - India</option>
      <option value="th-TH">Thai - Thailand</option>
      <option value="tr-TR">Turkish - Turkey</option>
      <option value="vi-VN">Vietnamese - Vietnam</option>
    </select>
  )
}
