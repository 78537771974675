export const PythonInformationModal = (props: any) => {

  const { setShowPythonModal } = props

  const hideModal = () => {
    setShowPythonModal(false)
  }

  return (
    <div id="python_modal" className="modal" style={{display: 'block'}}>
      <div ref={props.pythonModalRef} className="modal-content">
          <span id="close_python_modal" className="close" onClick={hideModal}>&times;</span>
          <p>
              For those comfortable with command-line operations, a Python script is available as an 
              alternative to the Praat script. Like the Praat script, the Python script converts sound 
              files to mono and accomplishes downsampling. The principal difference is the manner in 
              which it breaks up the sound files – rather than ending after a hard-coded interval of 
              time, the Python script uses silence detection to break up files, meaning that the 
              extracted files do not end during the middle of a speech event. The main advantage is 
              a possibly higher quality transcription, as Microsoft Speech will not be analyzing truncated 
              utterances.
              In order to run the scripts, the following system requirements must be met. First, 
              Python 3.5.4 must be installed; to do so will require administrator privileges on your 
              system. (Other versions of Python are not supported, but may work.) Windows 10, 8, 8.1, 
              and 7 have been tested.  Once testing is complete, we hope to have a version for the latest 
              versions of OS X.        
              The script and all associated files are available in the .zip file. Download the .zip file 
              appropriate to your operating system, then follow the instructions provided in the 
              INSTALL.txt file. To use the script, it is necessary to install the Python interpreter. 
              The installation script generates a README.txt file with brief instructions for using the 
              script. A more detailed how-to guide can be found in the appendix of the CLOX_guide.            
          </p>
      </div>
    </div> 
  )
}
