import React from 'react';
import { CloxLogo } from './CloxLogo';
import { UWLogo } from './UWLogo'
import { SocioLabLink } from './SocioLabLink'

export const Header = () => {
  return (
    <div id="header" className="flex">
      <div className="fake"></div>
      <CloxLogo />     
      <div className="navbar">
          <UWLogo />
          <SocioLabLink cssId='nav_li'>UW Sociolinguistics</SocioLabLink>
      </div>
    </div>
  )
}
